import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';
import linkPreviewData from '../../../data/previews/link.yml';
import pageHeroData from '../../../data/pages/components.yml';

const AvatarTeam = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.AvatarTeam;
    return <Component {...props} />;
  },
});

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const UniLink = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Link;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkType: 'default',
    };
  }

  onSwitch = value =>
    this.setState({
      linkType: value,
    });

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Link"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Link" />
        <V5Notice
          componentName="Link"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-link--docs"
        />

        <PageNavigation
          links={[
            'Type',
            'State',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Type">
          <Paragraph>
            There are three types of links: default, article and implied.
          </Paragraph>
          <ComponentPreview
            name="linkState"
            layout="split"
            previewData={linkPreviewData.types}
            onChange={this.onSwitch}>
            {this.state.linkType === 'default' && (
              <Text className="uni-padding--one--bottom" ignoreThisComponent>
                <UniLink type="default">Pay your bill</UniLink> anytime, or call
                our billing team with questions.
              </Text>
            )}
            {this.state.linkType === 'article' && (
              <Text className="uni-padding--one--bottom" ignoreThisComponent>
                To share video with individuals not on your
                <br />
                team, try an <UniLink type="article">Exchange</UniLink>,{' '}
                <UniLink type="article">download</UniLink>, or
                <br />
                <UniLink type="article">shareable link</UniLink>.
              </Text>
            )}
            {this.state.linkType === 'implied' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AvatarTeam sport="football" />
                <div className="uni-padding--half--left">
                  <UniLink type="implied">Football Highlights</UniLink>
                  <Text level="micro" className="uni-padding--quarter--top">
                    May 2nd at 4:19 PM
                  </Text>
                </div>
              </div>
            )}
            {this.state.linkType === 'button' && (
              <UniLink type="button">View Schedule</UniLink>
            )}
          </ComponentPreview>
        </Section>

        <Section title="State">
          <Paragraph>
            Links exist in a normal, hovered or active state.
          </Paragraph>
          <ComponentPreview name="linkState" layout="split">
            <ComponentWithLabel>
              <Text>
                <UniLink>Share this video</UniLink> with a message.
              </Text>
            </ComponentWithLabel>
          </ComponentPreview>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Inheriting Text Color</SectionSubhead>
          <Paragraph>
            Default and implied links inherit their text color from the context
            in which they’re placed (e.g., paragraph, list item). To ensure your
            link complies with Uniform guidelines, use one of our{' '}
            <Link href="/components/type" isDesignCodeLink>
              type elements
            </Link>
            .
          </Paragraph>

          <SectionSubhead>Links vs. Buttons</SectionSubhead>
          <Paragraph>
            Links should send the user outside of the current context. To keep
            the user in the same context, use a{' '}
            <Link href="/components/buttons/button" isDesignCodeLink>
              button
            </Link>{' '}
            instead.
          </Paragraph>
          <Paragraph>
            If you’re worried about the visual weight of a button, try the{' '}
            <Link href="/components/buttons/button/design#Style">
              minimal style
            </Link>
            .
          </Paragraph>
          <DontDo
            dontText="use a link to initiate an in-page action."
            doText="use a link to take the user to another page."
            imgFilename="link-linksvsbuttons"
          />

          <Paragraph>
            One exception: Buttons may replace links in landing pages and in-app
            promotions where the{' '}
            <strong>primary call-to-action will take the user elsewhere</strong>{' '}
            (e.g., promoting a trial sign-up in the mobile app).
          </Paragraph>
          <Paragraph>
            Just remember to limit the number of links and actions on a single
            page, especially primary buttons.
          </Paragraph>
          <DontDo
            dontText="force yourself to use one massive link for a primary CTA."
            doText="use a button in exceptional cases to take the user to a new flow."
            imgFilename="link-buttonaction"
          />

          <SectionSubhead>Implied Type</SectionSubhead>
          <Paragraph>
            If one screen features dozens of links, try the implied style.
          </Paragraph>
          <DontDo
            dontText="overwhelm the user with default or article links."
            doText="use the implied type when natural exploration reveals interactivity."
            imgFilename="link-impliedtype"
          />

          <SectionSubhead>External Links</SectionSubhead>
          <Paragraph>
            Use the UiNewWindow icon after any link to an external (non-Hudl)
            website.
          </Paragraph>
          <DontDo
            dontText="display the new window icon next to internal links."
            doText="show the new window icon after any external link."
            imgFilename="link-external"
          />
        </Section>

        <Section title="Microcopy">
          <Paragraph>
            Pay special attention to the words you wrap. The following
            guidelines can help.
          </Paragraph>

          <SectionSubhead>Avoid “Click Here”</SectionSubhead>
          <Paragraph>
            There are better words to link. Think of the children!
          </Paragraph>
          <DontDo
            dontText="cut corners by adding “click here” to your phrase."
            doText="wrap your link around a verb and its object—ideally both."
            imgFilename="link-avoidclickhere"
          />

          <SectionSubhead>Link Phrases, Not Sentences</SectionSubhead>
          <Paragraph>Links need not overpower the text.</Paragraph>
          <DontDo
            dontText="link entire sentences."
            doText="draw attention to the area where a user can take action."
            imgFilename="link-linkphrases"
          />

          <SectionSubhead>
            Send Users <em>Somewhere Else</em>
          </SectionSubhead>
          <Paragraph>
            Ideally, your link text should describe or allude to a new
            destination.
          </Paragraph>
          <DontDo
            dontText="use links to keep users in the current context."
            doText="focus your link text on that destination."
            imgFilename="link-somewhereelse"
          />

          <SectionSubhead>Balance the Findability</SectionSubhead>
          <Paragraph>
            If your link text is short, but the surrounding context is noisy,
            reconsider the style or rewrite the link to make it more noticeable.
          </Paragraph>
          <DontDo
            dontText="bury a link in a sea of microcopy (e.g., linking one small word)."
            doText="ensure the link stands out."
            imgFilename="link-findability"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Link" />

        <Section title="Platform">
          <PlatformTable
            platforms={{
              apple: (
                <List>
                  <li>
                    Avoid the use of Links except for actions and destinations
                    that launch Safari to take you out of the Hudl app.
                  </li>
                </List>
              ),
              android: (
                <List>
                  <li>
                    Avoid the use of Links except for actions and destinations
                    that launch Chrome to take you out of the Hudl app.
                  </li>
                </List>
              ),
            }}
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
